// pages/modal-example.js

import React, {useState} from 'react'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import { Link,useStaticQuery, graphql, PageProps, navigate } from "gatsby"

type DataProps = {
    site?: {
      buildTime: string
    }
  }

const Datenschutz:React.FC<PageProps<DataProps>> = ({path}) => {


    const [modal, setModal] = useState(false);
    
    return(
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div>
        {modal ? (
          <Link to={closeTo}>
            Schließen
          </Link>
        ) : (
          <>
          <header>
          <br/>
          <hr/>
            <h1>
            Datenschutzinformationen
            </h1>
          </header>
          <br/>
          <hr/>
          <h3>Allgemein </h3>
          <p>Schutz und Sicherheit Ihrer persönlichen Daten sind uns ein wichtiges Anliegen. Diese Applikation speichert und verarbeitet Daten daher nur im Einklang 
          mit den anwendbaren österreichischen und europäischen datenschutzrechtlichen Rechtsvorschriften. Sämtliche personenbezogene Daten werden in dieser Applikation 
          durch angemessene technische und organisatorische Maßnahmen vor Verlust und vor Zugriff und Manipulation durch Unbefugte geschützt. Wir möchten, dass Sie wissen,
           zu welchen Zwecken und auf Basis welcher Rechtsgrundlagen wir personenbezogene Daten erheben und wie wir diese Daten verarbeiten.
          Wir möchten Sie darüber hinaus über Ihre Rechte in Datenschutzangelegenheiten informieren und Ihnen mitteilen, an wen Sie sich diesbezüglich wenden können.
          </p>
           
          
           <br/><h3>Ihre Daten</h3> 
           <p>
          Welche Daten werden bei einem Besuch der Applikation verarbeitet?
          Besuchen Sie nur unsere Website so verarbeiten wir keine Zugangsdaten. Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. 
          Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt 
          automatisch, sobald Sie unsere Website betreten. 
          </p>
          
          
          <br/><h3>Woher stammen diese Daten?</h3>
          <p> Zunächst entstehen Daten durch den Besuch auf unserer Website (teilweise werden die Daten durch Ihren Browser übermittelt). Diese Daten wird erhoben, 
          um eine fehlerfreie Bereitstellung der Website zu gewährleisten bzw. um gesetzliche Pflichten zu erfüllen (Logging).
          </p>
          
          
          
          <br/><h3>Welchen Sicherheitsstandards unterliegt die Datenverarbeitung?</h3>
          <p> Wir verarbeiten in dieser Applikation personenbezogene Daten mit großer Sorgfalt und haben umfangreiche Sicherheitsmaßnahmen getroffen, um sicherzustellen, 
          dass die geltenden Datenschutzvorschriften sowohl von allen verantwortlichen Stellen als auch von den von uns beauftragten Auftragsverarbeitern 
          eingehalten werden. Im wesentlich folgen wir dem Minimalprinzip und speichern möglichst wenig. So wissen wir nicht ihren Namen, nicht ihre Anschrift, etc.
          </p>
          
          
          
          <br/><h3>Ihre Rechte</h3>
          <p>Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. 
          Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise 
          verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. In Österreich ist dies die Datenschutzbehörde, 
          Barichgasse 40-42, 1030 Wien, Telefon: +43 1 52 152-0, E-Mail: dsb@dsb.gv.at, www.dsb.gv.at.
          Sollten Sie ein Anliegen im Zusammenhang mit dieser Website bzw. hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten haben, 
          so können Sie sich z.B. mit einer kurzen Email direkt an uns wenden.
          </p>
          
    

          <div>
          
          <br/>
          <hr/>
          <h3>Allgemein </h3>
          <p>Schutz und Sicherheit Ihrer persönlichen Daten sind uns ein wichtiges Anliegen. Diese Applikation speichert und verarbeitet Daten daher nur im Einklang 
          mit den anwendbaren österreichischen und europäischen datenschutzrechtlichen Rechtsvorschriften. Sämtliche personenbezogene Daten werden in dieser Applikation 
          durch angemessene technische und organisatorische Maßnahmen vor Verlust und vor Zugriff und Manipulation durch Unbefugte geschützt. Wir möchten, dass Sie wissen,
           zu welchen Zwecken und auf Basis welcher Rechtsgrundlagen wir personenbezogene Daten erheben und wie wir diese Daten verarbeiten.
          Wir möchten Sie darüber hinaus über Ihre Rechte in Datenschutzangelegenheiten informieren und Ihnen mitteilen, an wen Sie sich diesbezüglich wenden können.
          </p>
          
          </div>

          </>
          
        )}

        <Link to="/"><h2>Zurück</h2></Link>
      </div>
    )}
  </ModalRoutingContext.Consumer>
)

        }

export default Datenschutz